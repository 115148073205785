import { useState, useEffect } from 'react'

export default function Animation() {
  const [slide, setSlide] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide((s) => (s < 3 ? s + 1 : 0))
    }, 1500)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="group flex items-center gap-1.5">
      <div className="relative w-6 lg:w-80 text-misty-300 text-xs flex items-center justify-end">
        <span className="group-hover:opacity-0 transition duration-150 ease-in-out absolute hidden md:inline">
          Hi!
        </span>
        <span className="md:hidden">
          {slide === 0 && 'NY'}
          {slide === 1 && 'BA'}
          {slide === 2 && 'VA'}
          {slide === 3 && 'BLR'}
        </span>
        <span className="opacity-0 group-hover:opacity-100 transition duration-150 ease-in-out absolute hidden md:inline">
          From New York  |  Buenos Aires  |  Valencia  |  Bangalore
        </span>
      </div>
      
      <div>
        {slide === 0 && (
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path opacity="0.982387" d="M7.49594 7.92965C7.21955 7.79145 6.67771 8.27547 6.61276 8.53526C6.40946 9.34852 6.44437 10.3252 6.53706 11.1595C6.56441 11.4056 6.50873 11.9733 6.83986 12.0679C7.47191 12.2485 8.8554 11.843 9.53986 11.7904C10.7639 11.6962 11.9787 11.4723 13.1987 11.3614C13.8315 11.3039 14.4956 11.3594 15.1164 11.2352C15.2504 11.2084 15.0912 9.99049 15.0912 9.7717C15.0912 9.36526 15.1284 7.46213 14.8641 7.1979C14.7828 7.11657 14.434 7.17265 14.3342 7.17265C14.1431 7.17265 13.8499 7.16247 13.6781 7.24836" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M7 12.25C5.25 12.25 5.54165 15.0562 5.54165 15.6585C5.54165 19.1894 5.25 22.7255 5.25 26.25" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M15.1665 11.0833C15.519 11.0833 15.464 11.0185 15.7954 11.1486C16.0964 11.2667 16.0999 11.9197 16.1288 12.1237C16.2542 13.0099 16.4654 13.8696 16.553 14.7635C16.8462 17.755 16.7045 20.7807 16.8863 23.7771C16.9345 24.5711 16.7206 25.4812 16.9166 26.2505" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M13.4761 5.55753C14.5014 5.21575 15.8003 4.92124 16.7312 5.70893C16.8523 5.81137 17.8422 7.19772 17.8919 7.19772C17.9167 7.19772 18.2437 6.87564 18.2957 6.84443C18.5986 6.66272 19.0708 6.66911 19.4059 6.69305C20.7277 6.78746 22.0643 7.61228 22.5853 8.86311C22.9538 9.7473 22.6336 10.9131 22.3331 11.765C21.9222 12.929 21.3064 14.0792 20.39 14.9191C20.1667 15.1239 19.8746 15.6473 19.5573 15.7266C19.2428 15.8053 18.7921 15.3962 18.4218 15.4491C17.862 15.529 17.1534 16.0411 16.8069 15.3481" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M16.7812 7.67676C16.8438 7.86436 17.5679 8.72769 17.7906 8.28235" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M17.8155 6.36441C17.7704 6.4547 17.8155 6.76813 17.8155 6.66721C17.8155 6.2884 17.4622 5.19111 17.8912 4.97656C17.9581 4.9431 17.9524 5.01353 17.9669 5.05226C18.1309 5.48947 18.1253 5.85923 18.0426 6.31395C18.0368 6.34578 18.0265 6.69245 17.9669 6.69245C17.9417 6.69245 17.8734 6.71029 17.8912 6.69245C18.1641 6.41958 18.3407 5.96827 18.5473 5.63263C18.9758 4.93637 19.5956 4.38156 20.3641 4.0934C20.8615 3.90686 21.9213 3.75822 21.7773 4.62329C21.6326 5.49058 19.6514 6.2993 18.9258 6.61675C18.685 6.7221 18.064 6.77593 17.9417 7.02048" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M6.84089 13.7964C6.80743 13.8095 6.73124 13.7237 6.73995 13.7586C6.78801 13.9508 6.89194 15.2393 6.94183 15.2726C7.01589 15.322 7.42085 15.1969 7.5222 15.1969C7.58455 15.1969 7.97669 15.2471 8.02687 15.1969C8.16857 15.0552 8.06948 14.3603 8.07733 14.1875C8.08409 14.0391 8.17992 13.6105 8.10257 13.4558C8.04629 13.3432 6.9021 13.7725 6.84089 13.7964Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M10.1676 13.4431C10.0897 13.465 10.0047 13.4554 9.92792 13.481C9.87534 13.4985 10.021 14.2432 10.0288 14.3137C10.0399 14.4131 9.98694 14.9614 10.1045 15.0202C10.2213 15.0786 11.4037 15.0686 11.5428 15.0454C11.6097 15.0343 11.6126 14.9221 11.6185 14.8688C11.6375 14.6981 11.6203 13.3195 11.5933 13.2791C11.5128 13.1582 10.1769 13.4405 10.1676 13.4431Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M13.558 13.2045C13.5146 13.2277 13.4673 13.2656 13.4192 13.2549C13.4028 13.2513 13.4656 13.2386 13.4697 13.2549C13.5129 13.4276 13.5258 13.6087 13.5454 13.7848C13.5688 13.9956 13.5122 14.4613 13.6211 14.6428C13.6823 14.7447 14.1747 14.6175 14.252 14.6175C14.3527 14.6175 14.5963 14.7021 14.6809 14.6175C14.7756 14.5229 14.7061 13.3392 14.7061 13.1288C14.7061 13.1121 14.7278 12.9084 14.6809 12.9017C14.302 12.8475 13.6779 13.1403 13.558 13.2045Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M6.97855 16.2563C6.88154 16.4585 6.86793 17.7512 7.04163 17.8207C7.24252 17.9011 7.63395 17.701 7.82386 17.6693C7.92829 17.6519 8.26168 17.6967 8.35377 17.6441C8.42108 17.6056 8.36043 15.9792 8.30331 15.903C8.20219 15.7682 6.98502 16.2427 6.97855 16.2563Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M10.3389 16.0372C10.1752 16.153 10.3369 16.7168 10.3642 16.9078C10.3747 16.9815 10.3661 17.4901 10.4146 17.5386C10.52 17.644 10.6949 17.4018 10.8436 17.4124C11.1347 17.4332 11.4329 17.4996 11.7268 17.4629C11.8063 17.453 11.7772 17.3665 11.7772 17.3115C11.7772 17.1063 11.7252 15.8464 11.6763 15.7975C11.5616 15.6828 10.439 15.9665 10.3389 16.0372Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M13.69 16.0599C13.548 16.2816 13.5041 17.224 13.7279 17.4478C13.7883 17.5082 14.3325 17.461 14.4344 17.473C14.4878 17.4793 14.9932 17.6111 15.0401 17.5487C15.1301 17.4286 15.0273 16.8917 15.0148 16.7665C14.9899 16.5177 15.0567 16.1394 14.9644 15.9085C14.9574 15.8911 14.9327 15.8833 14.9139 15.8833C14.6411 15.8833 14.2197 15.9181 13.955 15.9842C13.826 16.0165 13.6982 16.0472 13.69 16.0599Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M6.65111 18.9064C6.50879 19.0728 6.68176 20.3844 6.71419 20.3951C6.90745 20.4597 7.38351 20.4457 7.59737 20.4457C7.69074 20.4457 8.13616 20.4619 8.20296 20.3951C8.36647 20.2316 8.19502 19.4248 8.17772 19.2345C8.17305 19.183 8.18104 18.5666 8.12725 18.5532C8.06661 18.538 7.6967 18.6499 7.64783 18.6541C7.16229 18.6963 6.91713 18.5955 6.65111 18.9064Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M10.6741 18.7413C10.5427 18.8725 10.6463 20.1934 10.7624 20.2804C10.8171 20.3214 11.119 20.2244 11.1914 20.2299C11.2802 20.2368 11.8796 20.418 11.9484 20.2804C12.0284 20.1206 11.9295 19.7026 11.9231 19.5487C11.8835 18.5978 12.182 18.64 11.2166 18.6908C10.9626 18.7041 10.8171 18.5983 10.6741 18.7413Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M13.6399 18.6463C13.3536 18.7665 13.5099 19.8834 13.6525 20.0972C13.6679 20.1204 14.8914 19.9948 14.9646 19.9459C15.04 19.8956 14.9024 19.1773 14.8889 19.0627C14.8766 18.9583 14.91 18.6759 14.8637 18.5833C14.8204 18.4967 13.9393 18.5213 13.8291 18.558C13.7206 18.5942 13.6827 18.6284 13.6399 18.6463Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M6.7264 21.6313C6.55978 21.8359 6.71378 22.263 6.71378 22.5397C6.71378 22.6162 6.64843 23.0244 6.739 23.0697C7.00534 23.2028 7.62502 23.0344 7.87452 22.9689C8.02294 22.9297 8.23661 23.0159 8.32871 22.893C8.46114 22.7164 8.27539 22.0376 8.25301 21.8584C8.22797 21.6581 8.37913 21.4106 8.25301 21.2529C8.25159 21.2511 7.75158 21.454 7.74834 21.4548C7.43506 21.526 6.91481 21.4 6.7264 21.6313Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M10.9048 21.4423C11.005 21.4902 10.6781 21.5151 10.6146 21.6062C10.5971 21.6314 10.7107 22.7469 10.7408 22.767C10.7548 22.7763 10.7753 22.7723 10.7912 22.767C11.2475 22.6148 11.6682 22.7175 12.1286 22.6408C12.2547 22.6199 12.0529 21.5739 12.0529 21.4043C12.0529 21.3708 12.0767 21.3272 12.0529 21.3035C11.9029 21.1535 10.7723 21.3788 10.9048 21.4423Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M14.1124 21.473C13.955 21.6778 14.1127 22.0345 14.0997 22.2931C14.0874 22.5391 14.051 22.7726 14.0745 22.7726C14.1923 22.7726 14.364 22.6304 14.5035 22.621C14.6053 22.6143 15.1317 22.6918 15.1848 22.621C15.3912 22.3459 15.1343 21.5286 15.1343 21.2332C15.1343 21.208 15.1553 21.1715 15.1343 21.1575C15.1017 21.1358 14.7819 21.25 14.7306 21.2586C14.581 21.2835 14.3934 21.1874 14.2764 21.2837C14.1683 21.3728 14.1416 21.4349 14.1124 21.473Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M6.94131 24.2925C6.91647 24.5492 6.85148 25.2841 7.01701 25.491C7.03395 25.5121 7.09208 25.5265 7.11793 25.5162C7.31578 25.4371 8.33715 25.3248 8.35437 25.2386C8.40546 24.9834 8.30391 24.6167 8.30391 24.3556C8.30391 24.3477 8.3267 23.9165 8.30391 23.9013C8.218 23.8441 6.94374 24.2671 6.94131 24.2925Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M10.7164 24.1294C10.731 24.3512 10.7566 24.9897 10.9057 25.1386C10.9487 25.1816 11.6148 25.0743 11.6879 25.0629C11.8273 25.0416 12.3064 25.0248 12.4197 24.9116C12.4236 24.9077 12.3601 23.7303 12.344 23.6499C12.31 23.4801 11.8255 23.7962 11.7384 23.8267C11.5963 23.876 10.7042 23.9439 10.7164 24.1294Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M14.2069 23.9581C14.1511 24.3463 14.2065 24.5853 14.2574 24.9675C14.2659 25.031 14.2782 25.1771 14.3331 25.1441C14.5575 25.0093 14.5744 24.917 14.8883 24.917C14.9665 24.917 15.5445 24.984 15.5948 24.917C15.6597 24.8304 15.5217 23.6069 15.5191 23.6049C15.4732 23.5681 14.9226 23.7552 14.9135 23.7562C14.8768 23.7607 14.2397 23.7254 14.2069 23.7814C14.1554 23.8699 14.2129 23.9165 14.2069 23.9581Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M10.8259 4.19451C10.8231 4.20024 10.7323 3.40566 10.6997 3.21041C10.5901 2.55276 10.4727 1.82709 10.4727 1.1665" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M7.36914 7.87937C9.08144 7.87937 10.8315 7.61818 12.542 7.47564C13.0279 7.43514 13.5529 7.24854 14.0308 7.24854" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M7.9502 7.65229C8.3282 7.46329 8.60385 5.63603 9.03524 5.20465C9.54163 4.69825 10.75 4.4604 11.4576 4.37195C11.5907 4.35532 12.0068 4.21458 12.1137 4.32149C12.4288 4.63657 12.436 5.21798 12.7193 5.55792C12.9858 5.8777 13.5016 6.52598 13.5016 6.971" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M13.5522 5.60791L13.4512 5.63313L13.3251 5.65837L13.2746 5.70883L13.1737 5.75931L13.1232 5.78453L13.0728 5.83499H13.1484L13.2241 5.78453L13.2746 5.75931L13.3755 5.73407L13.426 5.68361L13.5269 5.63313" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M8.01332 9.09002C7.64934 9.41741 7.9839 10.2845 8.1521 10.705C8.20821 10.8452 8.55285 11.1313 8.70723 11.0078C9.30019 10.5334 9.31234 7.92158 8.01332 9.09002Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M10.6627 8.85047C10.3339 9.26475 10.7039 11.0874 11.3818 10.579C12.0948 10.0443 11.8393 7.36771 10.6627 8.85047Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M12.8704 8.73708C12.6109 8.82895 12.7942 10.6044 13.5013 10.5034C13.897 10.4469 13.9443 8.75337 13.577 8.45951C13.2428 8.19219 13.0351 8.67878 12.8704 8.73708Z" stroke="#C7F494" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        )}

        {slide === 1 && (
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path opacity="0.982387" d="M9.94044 7.60112C10.1644 7.88495 10.5401 7.90276 10.8362 8.09316C11.6172 8.59524 12.5029 8.90693 13.3091 9.35483C13.487 9.45364 14.2879 9.74034 14.4446 9.53147C14.6981 9.19342 14.2035 7.01376 14.167 6.50345C14.1218 5.86966 14.0178 5.24205 13.9652 4.61094C13.9403 4.31262 14.0622 3.78981 13.8642 3.5259C13.7719 3.40282 13.4372 3.7558 13.3848 3.80346C12.9784 4.17292 12.6146 4.57866 12.1988 4.93899C11.5351 5.51418 11.0009 6.23788 10.382 6.85672C10.1591 7.07961 9.76994 7.38501 9.94044 7.60112Z" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M9.8774 7.53784C9.82872 7.56219 9.90919 8.31947 9.8774 8.59766C9.80226 9.25499 9.67811 9.90829 9.59983 10.5659C9.20105 13.9155 8.7465 17.2565 8.36339 20.6088C8.26208 21.4953 8.14921 22.3773 8.01012 23.2582C7.98762 23.4009 8.05786 23.7896 7.90918 23.8639" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M14.3683 9.55632C14.3337 9.53905 14.4187 9.7745 14.4187 9.93483C14.4187 10.2135 14.444 10.4899 14.444 10.7675C14.444 11.636 14.3885 12.4987 14.3683 13.3666C14.3086 15.9312 14.1357 18.5557 14.343 21.1132C14.4144 21.994 14.5298 22.8533 14.4944 23.7375C14.4885 23.8858 14.5449 24.8019 14.5449 24.5702" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M13.9397 3.50122C14.2613 3.74237 14.6109 3.95746 14.9238 4.20775C15.6011 4.74957 16.1293 5.60853 16.892 6.02455C16.9882 6.07704 17.8259 6.88198 17.7752 7.00867C17.627 7.37904 16.8354 7.72772 16.5135 7.9423C15.7889 8.42539 15.107 9.14699 14.4948 9.75912" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M17.8008 7.03296C18.1607 7.75286 18.0985 8.57357 18.1793 9.35444C18.2728 10.259 18.368 11.1865 18.5325 12.0797C18.8454 13.778 18.9815 15.505 19.2643 17.2021C19.5141 18.701 19.848 20.2095 20.1727 21.6937C20.3935 22.7025 20.5318 23.7805 20.854 24.7469" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M18.8103 13.3673C19.0429 13.2898 19.3259 13.3675 19.5673 13.3421C20.125 13.2834 20.669 13.2412 21.2326 13.2412C22.0249 13.2412 22.8424 13.0782 23.6299 13.1907C23.8279 13.219 24.2455 13.0909 24.412 13.2159C24.5857 13.3462 24.6389 14.7023 24.6896 14.9823C24.8099 15.6437 24.7192 16.307 24.7653 16.9757C24.7996 17.4721 24.883 17.9386 24.8663 18.4393C24.8432 19.1329 24.6438 20.1942 24.8663 20.8618" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M9.24602 11.8274C7.32879 11.8274 5.96174 11.9052 4.37597 13.2152C3.64564 13.8186 3.18686 14.6567 2.53394 15.3096" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M9.34711 12.61C9.54112 12.513 8.68881 12.6573 8.53964 12.6857C8.09054 12.7712 7.63658 12.8437 7.20227 12.9885C5.7929 13.4583 4.37265 14.4842 3.69482 15.8399" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M9.32242 13.4423C9.23249 13.2624 8.16598 13.6603 7.95981 13.7703C6.85081 14.3618 5.71971 15.1411 5.3103 16.3693" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M2.66045 15.3856C2.52542 15.318 2.80431 15.5206 2.8371 15.537C3.02498 15.6309 3.2362 15.7013 3.44269 15.7388C4.1098 15.8601 4.73391 16.3192 5.4109 16.3192" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M5.48706 16.3699C6.04935 16.1824 6.81448 16.4332 7.37956 16.496C7.87566 16.5511 8.3917 16.5213 8.89356 16.5213" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M2.38273 15.4618C2.26316 15.2226 2.39639 15.9747 2.40797 16.0674C2.47288 16.5867 2.51667 17.1078 2.53414 17.6318C2.57879 18.972 2.59001 20.3168 2.659 21.6564" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M5.43579 16.2686C5.4728 16.1946 5.50524 17.0263 5.51149 17.1265C5.53767 17.5452 5.58395 17.9719 5.63767 18.3882C5.80129 19.6563 5.8143 21.0281 5.8143 22.2993" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M4.65869 22.4957C5.74251 22.4381 6.83113 22.4001 7.90898 22.4001" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M19.0872 15.2345C19.1882 15.2345 20.3661 15.1255 20.4246 15.184C20.6622 15.4216 20.4867 16.1409 20.6516 16.4709" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M19.1885 16.6471C19.519 16.4819 20.0827 16.5967 20.4501 16.5967" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M21.2576 15.1335C21.2576 15.5823 21.3838 16.0462 21.3838 16.4962" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M21.2825 15.1329C21.3458 15.0064 21.8449 15.023 21.9638 15.0068C22.0318 14.9975 22.4288 14.9113 22.5189 14.9563C22.6466 15.0201 22.7713 16.2422 22.7713 16.4451" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M21.4592 16.6217C21.9056 16.6217 22.3493 16.5964 22.7965 16.5964" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M19.4402 17.5056C19.7733 17.5056 20.1109 17.5561 20.4495 17.5561C20.4866 17.5561 20.7683 17.5216 20.7776 17.5308C20.8845 17.6379 20.8784 18.6979 20.8784 18.9187" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M19.6931 19.1199C20.0358 19.0057 20.4268 19.0564 20.7782 18.9685" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M21.4593 17.5308C21.3556 17.7379 21.4835 18.5626 21.5602 18.7924" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M21.5093 17.5056C21.5706 17.4648 23.0063 17.2862 23.0738 17.4552C23.2096 17.7945 23.0034 18.2788 23.1244 18.6411" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M21.7371 18.8678C22.2149 18.8678 22.7031 18.7668 23.1752 18.7668" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M20.1216 20.0794C21.0377 19.6214 21.2318 20.4614 21.2318 21.2402" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M20.172 21.4666C20.0535 21.4666 20.4532 21.4485 20.5001 21.4414C20.7306 21.4059 20.9732 21.3909 21.2066 21.3909" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M21.8123 19.928C21.8987 20.1008 22.115 21.3872 22.115 21.0636" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M21.8888 19.9783C21.8431 19.887 23.0037 19.7306 23.1755 19.9026C23.4154 20.1422 23.4028 20.9031 23.4028 21.2148" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.982387" d="M22.3178 21.341C22.1934 21.341 22.476 21.341 22.5196 21.341C22.7323 21.341 22.9473 21.2905 23.1504 21.2905" stroke="#B9E2F9" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        )}

        {slide === 2 && (
          <svg width="28" height="23" viewBox="0 0 23 23" fill="none">
            <g id="va">
              <path id="Vector" opacity="0.982387" d="M1.53564 22.6289C6.36719 22.6289 11.207 22.5145 16.0449 22.4019C17.3787 22.3708 18.723 22.3767 20.057 22.3767C20.4293 22.3767 21.0449 22.4755 21.3943 22.301" stroke="#FF8E73" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path id="Vector_2" opacity="0.982387" d="M1.61154 22.4523C1.54761 22.2604 1.60722 22.0453 1.5863 21.8467C1.47812 20.8188 1.36612 19.8009 1.28352 18.7681C1.15032 17.1032 0.980713 15.4435 0.980713 13.772" stroke="#FF8E73" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path id="Vector_3" opacity="0.982387" d="M21.4204 22.1241C21.3908 22.1389 21.4204 21.0914 21.4204 20.9128C21.4204 20.2384 21.395 19.5716 21.395 18.8942C21.395 16.7939 21.2185 14.7051 21.2185 12.6111" stroke="#FF8E73" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path id="Vector_4" opacity="0.982387" d="M0.905602 13.6958C0.800825 13.7657 0.963103 13.6685 0.829901 13.4687C0.746448 13.3435 0.474909 13.0753 0.501874 12.9136C0.546735 12.6443 0.98106 12.3861 1.18317 12.2575C1.31024 12.1766 1.45318 12.0796 1.61214 12.1061C2.04914 12.1789 1.94716 12.8701 2.19251 13.1154C2.32305 13.246 2.7396 13.1634 2.89904 13.1407C3.33077 13.079 2.70933 11.4783 3.80746 11.7528C4.49094 11.9237 3.93668 13.0066 4.28689 13.065C4.919 13.1703 4.7402 12.6729 4.94296 12.308C5.14736 11.94 5.9745 11.8255 6.28032 12.1313C6.38413 12.2351 6.81602 12.5761 6.78499 12.7622C6.72526 13.1206 6.01008 13.2774 5.85136 13.5949C5.71379 13.87 5.69308 14.2738 5.64949 14.579C5.44211 16.0307 5.45228 17.6629 5.57379 19.1211C5.66591 20.2265 5.54855 21.3877 5.54855 22.5022" stroke="#FF8E73" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path id="Vector_5" opacity="0.982387" d="M16.7774 22.1233C16.8291 22.2268 16.764 21.7908 16.7521 21.7195C16.7124 21.4813 16.7031 21.2533 16.6764 21.0131C16.5922 20.2546 16.5581 19.5002 16.4998 18.742C16.3989 17.4312 16.4237 16.113 16.3232 14.8056C16.2818 14.2677 16.4374 13.6995 16.3484 13.1654C16.3005 12.8781 15.9168 11.9481 15.9951 11.7524C16.0664 11.5742 16.2572 11.4872 16.3989 11.3739C17.0141 10.8816 17.188 12.163 17.5344 12.5094C17.6012 12.5762 17.8589 12.5346 17.9381 12.5346C18.0055 12.5346 18.1359 12.5639 18.1904 12.5094C18.5356 12.1641 18.0066 11.5806 18.5437 11.2224C19.1637 10.8091 18.9964 12.3633 19.5277 12.257C20.1314 12.1363 19.9404 11.5457 20.2596 11.1467C20.398 10.9738 20.5767 11.0266 20.7642 10.9954C22.3028 10.7389 21.0923 12.221 21.0923 12.7617" stroke="#FF8E73" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path id="Vector_6" opacity="0.982387" d="M9.20673 22.2496C9.20673 22.5207 9.13915 21.6813 9.13103 21.5431C9.10402 21.084 9.08055 20.6149 9.08055 20.1553C9.08055 18.7725 8.99273 17.2016 9.4843 15.8908C9.69931 15.3173 10.0373 14.6589 10.7712 14.8057C11.4211 14.9357 11.7044 15.6015 11.8815 16.1683C12.281 17.447 12.2852 18.821 12.2852 20.1553C12.2852 20.6956 12.3271 21.2138 12.3861 21.745C12.4033 21.8994 12.406 22.4929 12.4366 22.401" stroke="#FF8E73" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path id="Vector_7" opacity="0.982387" d="M8.12158 22.4014C8.0837 22.477 8.06757 22.1818 8.04588 22.0733C7.9955 21.8215 7.95668 21.5748 7.94494 21.3163C7.91485 20.6544 7.84399 19.9853 7.84399 19.3228C7.84399 17.6609 7.90722 15.9571 8.75241 14.4781C9.07616 13.9115 9.57932 13.2168 10.2916 13.1659C11.6525 13.0687 12.6875 14.2195 13.1683 15.3612C13.7578 16.7614 13.6525 18.3887 13.7234 19.8781C13.7653 20.7585 13.8243 21.6468 13.8243 22.5276" stroke="#FF8E73" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path id="Vector_8" opacity="0.982387" d="M1.33452 11.7532C1.33452 12.5655 1.25103 10.3031 1.23358 10.1635C1.08364 8.96386 1.31515 7.64662 0.956015 6.47943C0.757702 5.83488 0.776876 4.80718 1.61209 4.68785C2.36693 4.58003 2.01488 6.06876 2.69713 5.87384C3.30653 5.69971 2.88661 4.65235 3.25226 4.35983C3.41304 4.23121 3.73816 4.20489 3.93357 4.18318C4.84625 4.08177 4.23599 5.42793 4.58964 5.54579C5.41708 5.82161 5.23198 3.89253 6.20458 4.30937C7.40383 4.82331 6.29655 5.38051 6.38122 6.2271C6.50253 7.44019 6.62626 8.84522 6.53262 10.0626C6.50553 10.4146 6.37694 11.6942 6.53262 12.0056" stroke="#FF8E73" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path id="Vector_9" opacity="0.982387" d="M15.9945 11.2735C16.3835 11.2735 16.1459 9.90932 16.1459 9.60804C16.1459 8.3132 16.4645 6.82926 16.045 5.57068C15.9217 5.20076 15.3954 4.35745 15.6413 4.00621C15.75 3.85094 15.8855 3.72924 16.045 3.62771C16.9832 3.0307 17.0172 5.01795 17.5338 4.91461C18.3621 4.74896 17.9159 3.50018 18.7198 3.19874C19.8788 2.76409 19.3941 4.57281 19.7796 4.63705C20.548 4.76511 20.6735 2.90819 21.7477 3.12304C22.088 3.19107 22.3822 3.63534 22.2525 3.98097C22.1395 4.28218 21.851 4.47889 21.7477 4.78845C21.3638 5.94053 21.4945 7.17656 21.4198 8.3716C21.364 9.26236 21.2936 10.1533 21.2936 11.0463" stroke="#FF8E73" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path id="Vector_10" opacity="0.982387" d="M6.60791 6.80677C6.60791 6.74783 7.40098 6.69019 7.56677 6.57967C7.75116 6.45673 7.61707 6.17004 7.69295 5.9993C7.82047 5.71238 8.45404 5.40929 8.75275 5.49463C9.05852 5.582 9.06398 6.71665 9.56023 6.50397C10.2499 6.20841 9.57244 5.36851 10.5191 5.2423C10.5296 5.2409 10.8248 5.2185 10.8724 5.2423C11.0044 5.30835 11.203 5.43268 11.3013 5.54509C11.5087 5.78214 11.3837 6.38268 11.8817 6.32732C12.674 6.23931 11.8045 5.04438 12.992 5.2423C13.587 5.34146 13.1111 6.26767 13.648 6.32732C14.2731 6.39678 13.9341 5.42276 14.6069 5.54509C15.2478 5.66162 14.7718 6.43669 15.1621 6.68061C15.3773 6.81511 15.6778 6.67333 15.8938 6.63013" stroke="#FF8E73" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path id="Vector_11" opacity="0.982387" d="M2.09139 1.6342C2.07457 1.60056 2.03474 1.49615 2.04093 1.53326C2.1296 2.06526 2.34156 2.56751 2.44468 3.09773C2.58352 3.81183 2.84841 4.67849 2.84841 5.39397" stroke="#FF8E73" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path id="Vector_12" opacity="0.982387" d="M2.3936 1.83672C2.38658 1.83905 3.19675 1.44445 3.42818 1.33205C4.1181 0.996941 4.90798 0.559573 5.64872 0.347941C6.28931 0.164915 6.22071 1.8073 6.6833 2.03858" stroke="#FF8E73" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path id="Vector_13" opacity="0.982387" d="M2.7478 3.67826C3.11146 3.58736 3.44937 3.28965 3.78238 3.12313C4.38207 2.82328 5.02605 2.61556 5.64966 2.36613C5.95263 2.24494 6.42006 2.27702 6.659 2.03809" stroke="#FF8E73" strokeWidth="0.405797" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
          </svg>
        )}

        {slide === 3 && (
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.33301 12.1305C2.33301 12.0303 2.39971 12.4549 2.40379 12.508C2.42799 12.8226 2.45362 13.1374 2.4746 13.452C2.53901 14.4182 2.57074 15.3879 2.63977 16.3545C2.77805 18.2902 2.85573 20.2274 2.99375 22.1596C3.05548 23.0238 2.91634 23.9175 3.04094 25.0386" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.01709 25.133C3.3761 25.133 3.69265 25.3024 4.079 25.2748C8.0714 24.9894 12.0645 24.7696 16.0669 24.5197C19.1486 24.327 22.2575 24.6139 25.3408 24.6139" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M25.4356 24.5431C25.3297 24.5961 25.2649 23.2135 25.2468 23.0327C25.1514 22.079 25.1523 21.1117 25.1523 20.1539C25.1523 17.1869 25.7424 12.9456 25.7424 11.3242" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.20898 11.3517C8.60871 11.4016 9.00876 11.3989 9.41249 11.3989C10.3647 11.3989 11.3168 11.3633 12.2679 11.3281C14.8821 11.2313 17.5115 11.0449 20.1261 11.0449" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.30348 11.3994C8.30401 11.3984 7.54833 11.9894 7.54833 11.9894C7.54833 11.9894 6.77576 12.9141 6.41563 13.4052C6.37009 13.4673 6.11362 13.7734 6.22683 13.83" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.27393 13.8531C6.27393 14.0425 7.5166 14.1434 7.66623 14.1363C9.27718 14.0596 10.8964 13.9813 12.5038 13.9239C15.0057 13.8345 17.5119 13.8738 20.008 13.7351C20.2782 13.7201 22.1318 13.7116 22.179 13.6407C22.2719 13.5013 21.8734 13.0821 21.8015 12.9564C21.41 12.2712 20.6618 11.7388 20.3148 11.0449" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.4751 12.131C3.92955 12.131 7.89321 11.6102 8.20942 11.3994" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.54541 12.0119C2.54541 11.7972 3.78731 10.2796 4.12648 10.1948C4.65465 10.0627 5.33782 10.2287 5.89635 10.124C6.39181 10.0311 7.14343 9.76322 7.64261 9.88801C7.95702 9.96662 8.17364 10.9265 8.30336 11.1859" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.3623 11.0693C20.7629 11.0693 21.1008 11.2762 21.4216 11.2762C22.7977 11.2762 24.3747 11.3997 25.7427 11.3997" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.362 10.9347C20.2866 10.897 21.1667 10.0642 21.4003 9.88901C21.8076 9.58358 23.3881 9.55096 23.9725 9.60585C24.7262 9.67664 24.6963 9.69193 24.8029 9.74744C25.4475 10.0826 25.6716 10.917 25.7424 11.3049" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.01123 14.2308C9.07183 14.1096 9.07765 14.5542 9.08204 14.5848C9.12207 14.8651 9.17747 15.1524 9.20003 15.4344C9.25933 16.1757 9.26878 16.9326 9.29441 17.6762C9.32863 18.6683 9.24723 19.6349 9.24723 20.6258C9.24723 21.6722 9.31776 22.7763 9.22363 23.8117C9.20121 24.0582 9.10564 24.3232 9.10564 24.5668" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.5592 13.9707C19.3961 14.623 19.7176 15.4779 19.6772 16.1653C19.5627 18.1117 19.4444 20.1439 19.6064 22.0884C19.623 22.2875 19.7891 24.398 19.9368 24.4719" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.8926 17.4326C22.9021 18.3687 22.9682 20.2238 22.9054 21.2014" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.9668 16.1188C22.0544 16.0019 22.1736 15.9053 22.2263 15.7648C22.4313 15.2181 22.5591 15.9667 22.8162 16.0952" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.56678 17.2988C5.5952 17.225 5.76675 21.7356 5.77591 21.7118" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.09362 16.5434C5.06114 16.4785 5.47142 15.8081 5.58918 15.9063C5.76489 16.0527 5.87096 16.3061 6.03755 16.4726" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.5277 13.9004C12.503 13.9498 12.6269 13.8504 12.6358 13.9712C12.7366 15.3502 12.5867 16.3683 12.5984 16.7322C12.683 19.3521 12.7636 21.9481 12.7636 24.5667" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.5713 13.9238C15.5713 15.0945 15.7931 16.2704 15.8309 17.44C15.87 18.6526 15.785 19.8858 15.7365 21.0976C15.7052 21.8794 15.6941 22.653 15.6421 23.4339C15.6305 23.6074 15.6185 24.4928 15.6185 24.2835" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.22386 11.2584C9.12879 11.3535 9.25685 11.0553 9.20026 10.8101C9.16397 10.6528 8.87547 9.90774 9.03507 9.74814C9.06942 9.71377 11.2366 9.54367 11.3713 9.53575C13.356 9.41901 15.3319 9.32356 17.318 9.22897C17.5036 9.22013 19.2838 9.1417 19.3475 9.20539C19.5905 9.44841 19.5545 10.5818 19.6778 10.9516" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.6775 11.0453C19.7962 10.9262 19.3479 8.33456 19.2764 7.69434" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.98709 10.0596C8.73 9.36979 8.80359 9.01115 8.70448 8.61466C8.69172 8.56362 8.6535 8.52402 8.70448 8.47307C8.82933 8.34821 10.911 8.15981 11.1823 8.11911C12.8487 7.86914 14.5486 7.84056 16.2323 7.74154C17.134 7.68849 18.5433 7.74154 19.2764 7.69434" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.66943 10.2905C4.66943 10.0376 4.75042 11.2762 4.75928 11.7654" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.41504 10.2656C6.41504 10.5066 6.5394 10.9683 6.63796 11.5582" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.3916 9.74805C22.5016 9.74805 22.3916 10.9353 22.3916 11.2229" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24.1611 9.67676C24.1611 10.2277 24.1611 10.7798 24.1611 11.3286" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.46862 14.4198C8.45267 14.4995 8.46104 15.2618 8.53941 15.3402C8.57458 15.3753 8.63441 15.3104 8.681 15.2929C8.84464 15.2316 9.7596 15.3373 9.8373 15.1042C9.89073 14.9439 9.80429 14.7125 9.76651 14.5614C9.74841 14.489 9.66234 14.2823 9.69571 14.349C9.75401 14.4656 9.64818 14.7166 9.62492 14.8446C9.61497 14.8993 9.64065 15.0491 9.60132 15.0098C9.5556 14.964 9.56229 14.4119 9.43613 14.4434C9.33741 14.4681 9.30508 14.7602 9.29454 14.8446C9.28956 14.8844 9.27094 15.0027 9.27094 14.9626C9.27094 14.7943 9.36672 14.5454 9.24736 14.3962C9.1185 14.2351 8.97306 14.8682 8.89337 14.8682C8.8868 14.8682 8.76774 14.6532 8.7046 14.6322C8.58316 14.5917 8.48252 14.7686 8.53941 14.8682C8.69188 15.135 9.10956 14.5818 8.82259 14.467C8.80798 14.4612 8.78319 14.4534 8.77538 14.467C8.4402 15.0536 9.61977 14.4539 9.20015 14.349" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.0559 14.1602C11.9958 14.2804 11.9469 15.046 12.0559 15.1277C12.0846 15.1492 12.2543 15.1064 12.2683 15.1041C12.4504 15.0737 13.1594 15.086 13.2594 14.9861C13.3356 14.9099 13.184 14.6675 13.165 14.5849C13.1642 14.5817 13.1366 14.2546 13.0942 14.2546C13.0517 14.2546 12.9079 14.6532 12.8582 14.7029C12.8163 14.7448 12.8952 14.3152 12.8818 14.3017C12.768 14.188 12.7396 14.6793 12.7638 14.6793C12.8551 14.6793 12.8191 14.1602 12.7874 14.1602C12.7243 14.1602 12.788 14.3887 12.8346 14.4197" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.5243 14.2102C12.1165 13.8109 12.2035 15.2311 12.2679 14.8446C12.2761 14.7955 12.3309 14.7502 12.3151 14.703C12.274 14.5795 12.5563 14.2416 12.5243 14.2102Z" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.0288 14.0417C14.7635 15.103 15.1887 14.9096 15.9019 14.8205C15.9295 14.817 16.4418 14.8247 16.4447 14.8205C16.4504 14.812 16.2843 14.3503 16.2795 14.3249C16.2576 14.2079 16.3388 14.0242 16.2323 13.971C16.1811 13.9453 16.199 14.0806 16.1851 14.1362C16.1653 14.2153 16.0501 14.7025 15.9491 14.7025C15.8063 14.7025 15.9191 14.2005 15.8311 14.1126C15.7422 14.0236 15.5244 14.4927 15.5244 14.5609C15.5244 14.6754 15.6026 14.3439 15.6188 14.2305C15.631 14.1445 15.6871 14.0455 15.6424 13.971C15.5482 13.814 15.283 14.3539 15.2648 14.3721C15.1906 14.4463 15.2306 14.0653 15.3356 14.0653C15.3425 14.0653 15.4856 14.2283 15.5244 14.2541" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.206 14.0182C19.2765 14.0707 19.0291 14.0354 18.9464 14.0654C18.898 14.083 18.9402 14.84 19.0172 14.8914C19.0721 14.928 19.176 14.75 19.2296 14.7262C19.384 14.6575 19.6312 14.6737 19.7959 14.6554C19.8258 14.652 20.2679 14.65 20.2679 14.561C20.2679 14.4366 20.1167 14.2144 20.0791 14.089C20.0626 14.0342 20.0892 13.9238 20.0319 13.9238C19.9011 13.9238 19.8644 14.5839 19.7015 14.5374C19.5081 14.4821 19.68 14.3035 19.5835 14.207C19.5022 14.1257 19.2768 14.3066 19.2768 14.3958C19.2768 14.4218 19.6331 13.9498 19.442 13.971C19.3684 13.9792 18.9683 14.3102 19.0644 14.3486C19.1263 14.3733 19.6525 14.2827 19.6071 14.207C19.506 14.0384 19.0827 13.9266 19.206 14.0182Z" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.4298 14.6562L15.2646 14.7506" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.94045 24.8276C8.86036 24.8276 8.6651 23.9937 8.75166 23.9072C8.80295 23.8558 8.8974 23.983 8.96406 23.9542C9.11108 23.8912 9.42238 23.6878 9.5776 23.7655C9.75815 23.8558 9.69851 24.5208 9.76639 24.5208C9.85432 24.5208 9.74425 24.0001 9.6248 24.1195C9.56532 24.1791 9.51722 24.3822 9.50681 24.4498C9.49844 24.5043 9.50681 24.6701 9.50681 24.615C9.50681 24.589 9.46895 24.0629 9.41241 24.1195C9.28339 24.2485 9.17844 24.7438 9.34162 24.6623C9.43729 24.6144 9.38883 24.3329 9.38883 24.261C9.38883 24.2297 9.40289 24.1387 9.38883 24.1667C9.35356 24.2373 9.28179 24.497 9.41241 24.497" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.433 24.6382C12.3932 24.5984 12.2053 23.9221 12.2206 23.9068C12.2588 23.8685 12.466 23.852 12.5267 23.8379C12.577 23.8265 13.1389 23.6913 13.1881 23.7651C13.2976 23.9292 13.2354 24.3551 13.2354 24.3551C13.2354 24.3551 13.3052 23.8945 13.1409 23.8123C13.1211 23.8023 13.1008 23.8383 13.0938 23.8596C13.0557 23.9737 13.0389 24.0942 13.023 24.2136C13.0136 24.2837 13.0546 24.4891 13.023 24.4259C13.0085 24.3971 12.5741 24.0111 12.5267 24.1061C12.4871 24.1853 12.7467 24.264 12.8342 24.3079" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.2172 24.2718C14.8565 24.5649 15.2266 23.4514 15.5712 23.3437C15.6408 23.322 15.9406 23.4286 16.0196 23.4813C16.1364 23.5592 16.114 23.9159 16.114 24.0241C16.114 24.0812 16.1356 24.2405 16.1612 24.1892C16.1904 24.1307 16.1408 23.716 16.0196 23.7644C15.8168 23.8456 15.878 24.3501 15.878 24.2837C15.878 24.1892 15.878 24.0949 15.878 24.0004C15.878 23.9906 15.8304 23.5761 15.7364 23.6701C15.6716 23.7349 15.5638 24.3307 15.5476 24.3307C15.3895 24.3307 15.6122 23.9707 15.5004 23.8589C15.4747 23.8331 15.2983 24.1501 15.288 24.1657C15.2068 24.2875 15.2722 24.2271 15.2172 24.2718Z" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.2055 24.3302C19.1741 24.2361 19.2057 23.3405 19.2763 23.3405C19.4447 23.3405 20.1776 23.162 20.3146 23.5043C20.3598 23.6176 20.3206 23.8294 20.3382 23.9526C20.3383 23.9538 20.3469 24.18 20.3618 24.1652C20.4242 24.1028 20.2874 23.7835 20.2674 23.7169C20.2522 23.6662 20.2259 23.5313 20.1966 23.5751C20.1024 23.7164 20.104 24.0239 20.0786 24.1887C20.0738 24.2197 20.0786 24.3147 20.0786 24.2832C20.0786 24.246 20.1349 23.4891 19.9842 23.5751C19.7955 23.6831 19.8117 24.1161 19.7482 24.3067C19.7308 24.359 19.7482 24.1967 19.7482 24.1414C19.7482 24.0944 19.7683 23.6931 19.7246 23.6931C19.5384 23.6931 19.5889 24.3183 19.5123 24.1652C19.4707 24.0818 19.4415 23.3343 19.4415 23.8584" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.05698 10.1706C4.17869 10.1706 4.00934 9.20313 4.00879 9.03328C4.00728 8.55487 4.79885 8.4249 5.08292 8.19764C5.21871 8.08901 5.36366 7.89634 5.45119 7.75044C5.45592 7.74258 5.55462 7.51186 5.55642 7.51368C5.6184 7.57566 5.92808 7.84772 6.03785 7.93005C6.32991 8.14909 7.12458 8.14503 7.25064 8.55487C7.35217 8.88491 7.25064 9.17971 7.25064 9.74719" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.3926 9.74989C21.5222 9.49052 21.4337 9.055 21.603 8.82918C21.9225 8.40329 22.1839 8.47223 22.5763 8.14522C22.645 8.08809 23.0574 7.69434 23.0757 7.69434C23.0778 7.69434 23.7648 8.12057 23.8128 8.14522C24.6085 8.55506 24.6014 8.24127 24.7238 8.79919L24.726 9.60278" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.37033 7.27388C5.48542 7.27388 5.5916 7.20963 5.69916 7.16866C5.7815 7.13729 5.5216 7.17359 5.43609 7.19498C5.38596 7.20751 5.06138 7.25457 5.09411 7.35279C5.20564 7.6874 5.89779 7.36943 6.04114 7.27388C6.06178 7.26012 6.1075 7.24191 6.09376 7.22127C5.99859 7.07854 5.19875 7.11842 5.14672 7.3265C5.07542 7.61173 6.02389 7.43257 5.96221 7.24759C5.93744 7.17324 5.47079 7.22127 5.4098 7.22127C5.32895 7.22127 5.09219 7.27388 5.17304 7.27388C5.32312 7.27388 5.26357 7.27388 5.37033 7.27388Z" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.51514 7.22107C5.51514 7.03018 5.60548 7.21849 5.51514 6.53711" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23.1811 7.31505C23.5815 6.90472 22.4575 7.14839 22.4575 7.3019C22.4575 7.3927 22.7746 7.73961 23.1476 7.67017C23.272 7.64703 23.649 7.33543 23.694 7.2756C23.6979 7.27042 22.8727 7.11365 22.7207 7.14406C22.2493 7.23833 22.6728 7.53911 22.9312 7.43342C23.0873 7.36958 23.1375 7.35965 23.1811 7.31505Z" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23.1284 6.84218C23.0143 6.64474 23.1259 6.82899 23.1417 6.90795C23.1485 6.94235 23.1478 7.04769 23.1417 7.01318C23.1179 6.88263 23.1759 6.92413 23.1284 6.84218Z" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.3179 8.25039C10.3179 8.18563 10.1657 8.48187 10.1075 8.56605C9.98537 8.74238 9.58258 9.58953 9.66026 9.43415" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.3693 8.14551C12.1518 8.36306 11.801 8.87724 11.3682 9.45342" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.1677 7.9352C14.1209 7.84166 13.5883 9.22574 13.5537 9.32941" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.8413 7.93066C15.8413 7.93066 15.3973 8.95663 15.2109 9.3294" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.124 7.93066C17.9358 8.25087 17.5936 8.84275 17.2451 9.18032" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.5812 8.14493C9.42427 7.98798 9.30275 7.01899 9.81465 5.8702C10.1248 5.17423 12.1341 5.06553 12.7633 4.59363C13.2094 4.25902 13.1434 3.87769 13.4745 3.43616C13.5098 3.3891 13.4946 3.27832 13.5534 3.27832C13.5926 3.27832 13.5885 3.34846 13.606 3.38355C13.7254 3.62229 14.1212 4.37087 14.3582 4.50588C14.839 4.77968 16.9159 4.66716 17.2449 5.53984C17.6103 6.13183 17.8949 6.76991 18.0567 7.50149" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.8372 3.01835C13.8372 2.75017 12.9546 3.00849 13.0217 3.17619C13.0955 3.36081 13.8635 3.26768 13.8635 3.12358C13.8635 2.99027 13.2894 3.03999 13.2322 3.09726" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.4162 2.33398C13.3925 2.38146 13.5374 2.83059 13.5477 2.91272" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.639 7.73214C12.4477 7.34944 13.4478 6.04812 13.5092 6.0686C13.5645 6.08703 14.2076 6.58784 14.277 6.65724C14.5829 6.96313 14.7027 7.25272 14.8912 7.62977" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.60645 13.5941C3.62084 13.3405 4.71859 13.3138 5.75437 13.2102C6.11315 13.1743 6.44858 13.0566 6.80368 13.0566" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.4941 12.5452C21.6758 12.3635 22.4254 12.486 22.6715 12.4684C23.5841 12.4032 24.5245 12.2637 25.4355 12.2637" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.443 10.3441C14.2629 10.5227 14.6536 10.5024 14.6094 10.3697C14.5391 10.1589 14.2135 10.3294 14.3278 10.4209C14.437 10.5083 14.6485 10.4293 14.5838 10.2674C14.5567 10.1997 14.4662 10.3212 14.443 10.3441Z" stroke="#FFE2DA" strokeWidth="0.408333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>          
        )}
      </div>
    </div>
  )
}